@use '@angular/material' as mat;

@import 'assets/styles/_variables';

// 全体のタイポグラフィを設定
@include mat.all-component-typographies($app-typography-config);
@include mat.core();

// コントロールの個別設定
@include mat.list-typography($app-list-typography-config);

@include mat.all-component-themes($my-theme);


html, body { height: 100%; }
body {
  margin: 0;
}
